import React, {useState, useCallback, useEffect} from 'react';
import Image from "next/image";
import searchIcon from 'assets/search.svg';
import debounce from "lodash.debounce";
import {useRouter} from "next/router";
import {getSearchSuggestions as getSearchSuggestionsApi} from 'data/search';
import {CountryPreference, CompletionResult, ReduxState} from 'types';
import crossIcon from 'assets/button-cross.svg';
import arrowUpRightIcon from 'assets/arrow-up-right.svg';
import imageSearchIcon from 'assets/image-search.svg';
import { useDispatch, useSelector } from 'react-redux';
import { checkCountryPreference, getPathNameWithStoreType } from 'utils/misc';
import { COUNTRY_CODE } from 'constants/store';
import useBreakpoint from 'hooks/useBreakpoint';
import { Screen } from 'constants/breakpoints';
import ImageSearchPopup from 'components/ImageSearchPopup/ImageSearchPopup';
import cameraBlackIcon from '../../../assets/camera-black.svg';
import uploadCloudBlackIcon from '../../../assets/upload-cloud-black.svg';
import { setImageSearchDataAction } from '../../../actions/usersAction';
import { toast } from 'react-toastify';
import Loader from '../../Loader/_default/Loader';
import { searchPerformedEvent } from 'handlers/analytics/common';

interface SearchWidgetProps {
  autoFocus?: boolean;
  onSearchClick?: () => void;
  userCountryPreference: CountryPreference;
}

function SearchWidget({ autoFocus, onSearchClick, userCountryPreference }: SearchWidgetProps) {
  const isSmScreen = useBreakpoint(Screen.SmScreen);
  const selectedCountry = useSelector((state: ReduxState) => state?.user?.selectedCountry);
  const isUSCountryPreference = checkCountryPreference(selectedCountry, COUNTRY_CODE.us);

  const [autoSuggestions, setAutoSuggestions]: [any, Function] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [suggestionText, setSuggestionText] = useState('');
  const [defaultSuggestion, setDefaultSuggestion] = useState('');
  const [showAutoSuggestions, setShowAutoSuggestions] = useState(false);
  const [imageSearchPopupVisible, setImageSearchPopupVisible] = useState(false);
  const [isImageUploadLoading, setIsImageUploadLoading] = useState(false);
  const router = useRouter();
  const dispatch: any = useDispatch();

  const fetchSearchSuggestions = async (input: any) => {
    if (input.length >= 3) {
      const suggestionsRes = await getSearchSuggestionsApi(input);
      setAutoSuggestions(suggestionsRes.slice(0, 6));
    }
  };

  useEffect(() => {
    const searchInput = router.query.suggestedText?.toString();
    if (searchInput) {
      setSearchInput(searchInput);
      fetchSearchSuggestions(searchInput);
    }
  }, []);

  const handleInputChange = (e: any) => {
    const {value} = e.target
    // setShowAutoSuggestions(true);
    setSearchInput(value);
    // setAutoSuggestions([]);
    // setSuggestionText('');
    // setDefaultSuggestion('')
    // fetchSuggestions(value);
  };

  const fetchSuggestions = useCallback(debounce(async (value: string) => {
    if (value && value.length >= 3) {
      const suggestionsRes = await getSearchSuggestionsApi(value);
      const newSuggestions = suggestionsRes.slice(0, 6);
      setAutoSuggestions(newSuggestions);
      if (newSuggestions.length > 0 && newSuggestions[0].text.startsWith(value.toLowerCase()) && newSuggestions[0].text.length > value.length) {
        setSuggestionText(value + newSuggestions[0].text.slice(value.length));
      } else if (newSuggestions.length > 1 && newSuggestions[1].text.startsWith(value.toLowerCase()) && newSuggestions[1].text.length > value.length) {
        setSuggestionText(value + newSuggestions[1].text.slice(value.length));
      } else {
        setSuggestionText('');
      }
    } else {
      setAutoSuggestions([]);
      setSuggestionText('');
    }
    setDefaultSuggestion(suggestionText);
  }, 500), []);
  

  // Apply only input search param when user click on the search button
  const onSearch = (e: any) => {
    const {value} = e.currentTarget;
    setSearchInput(value);
    setShowAutoSuggestions(false);

    searchPerformedEvent(true, value);
    router.push(getPathNameWithStoreType(`/search?suggestedText=${value}`));
    onSearchClick && onSearchClick();
  };

  const onTab = (e: any) => {
    e.preventDefault();
    setSearchInput(suggestionText);
    fetchSuggestions(suggestionText);
  };

  const onClickSuggestion = (e: any) => {
    const {value} = e.currentTarget;
    setSearchInput(value);
    setShowAutoSuggestions(false);
    fetchSuggestions(value);
    router.push(getPathNameWithStoreType(`/search?suggestedText=${value}`));
    onSearchClick && onSearchClick();
  };

  const handleMouseEnter = (text: String) => {
    if (text.toLowerCase().startsWith(searchInput.toLowerCase()) && text.length > searchInput.length) {
      setSuggestionText(searchInput + text.slice(searchInput.length));
    } else {
      setSuggestionText('');
    }
  }

  const handleMouseLeave = () => {
    setSuggestionText(defaultSuggestion);
  }

  const handleMouseDown = (e: any) => {
    e.preventDefault();
  };
  

  const handleFocusChange = (e: any) => {
    if (e.type === 'focus') {
      setShowAutoSuggestions(true);
    } else {
      setShowAutoSuggestions(false);
    }
  };

  const clearInput = () => {
    setSearchInput('');
    setAutoSuggestions([]);
    setSuggestionText('');
    setDefaultSuggestion('');
    setShowAutoSuggestions(false);
  };

  const getSearchPlaceholder = () => {
    if (isUSCountryPreference) return 'Search Products by C-Code';

    if (isSmScreen) return 'e.g. Saree, T-shirt, USB Cable';

    return 'Search Products (e.g. Magic Book, Bedsheet etc.)';
  }

  const isLgScreen = useBreakpoint(Screen.LgScreen);
  const isXsScreen = useBreakpoint(Screen.XsScreen);

  const onImageUpload = useCallback(async (acceptedFiles, searchCta) => {
    if (!acceptedFiles[0]) {
      return;
    }
    if (!acceptedFiles[0].type.includes('image')) {
      toast.error('Can’t upload file. Only images are allowed');
      return;
    }
    if (acceptedFiles[0].size > 20000000) {
      toast.error('Can’t upload image. Image size should be less than 20MB');
      return;
    }
    let file: File | Blob;
    if (acceptedFiles[0].size > 2000000) {
      // Dynamically load compressorjs.js
      const Compressor = (await import('compressorjs')).default;
      file = await new Promise((resolve, reject) => {
        new Compressor(acceptedFiles[0], {
          quality: 0.8,
          success(result) {
            resolve(result);
          },
          error(err) {
            console.log(err.message);
            reject(err);
          },
        });
      })
    } else {
      file = acceptedFiles[0];
    }
    const formData = new FormData();
    formData.append('file', file);
    setIsImageUploadLoading(true);
    fetch(`/api/imagesearch?tilesInRow=${isXsScreen ? 2 : isLgScreen ? 4 : 5}`, {
      method: 'POST',
      body: formData,
    })
      .then(async res => {
        if (!res.ok) {
          const errorRes = await res.json();
          throw new Error(errorRes.message);
        }
        return res.json();
      })
      .then((imageSearchData) => {
        dispatch(setImageSearchDataAction({
          ...imageSearchData,
          imageFileUrl: URL.createObjectURL(file),
        }));
        onSearchClick && onSearchClick();
        setIsImageUploadLoading(false);
        searchPerformedEvent(false, '', searchCta);
        router.push({
          pathname: getPathNameWithStoreType('/search'),
          query: {
            type: 'image',
            imgHash: URL.createObjectURL(file).replace(/^[\w:/]+\//, '')
          }
        }, undefined, { shallow: true });
      })
      .catch(e => {
        setIsImageUploadLoading(false);
        if (e.name === 'AbortError') {
          console.log('Fetch aborted');
        } else {
          console.log('err: ', e.message);
          toast.error(e.message);
          onSearchClick && onSearchClick();
        }
      });
  }, []);

  return (<>
  <div
    className={'relative flex rounded-[4px] text-[14px]'}>
    <div className="peer relative inline flex-1">
      <input
        id='search-widget-input'
        className={'bg-theme_1_greybg placeholder-theme_1_grey4 placeholder:text-[15px] text-theme_1_grey1 p-[12px] smScreen:p-[9px] rounded-bl-[4px] rounded-tl-[4px] border border-transparent focus:bg-theme_1_white focus:border-theme_1_black focus:border-r-0 w-full smScreen:text-base'}
        type="text"
        placeholder={getSearchPlaceholder()}
        onChange={handleInputChange}
        onFocus={handleFocusChange}
        onBlur={handleFocusChange}
        value={searchInput}
        onKeyDown={(e: any) => {
          if (e.keyCode === 13) {
            onSearch(e);
          } else if (e.key == 'Tab' && suggestionText.length != 0) {
            onTab(e);
          }
        }}
        autoFocus={autoFocus}
      />
      {showAutoSuggestions && <span className="text-gray-500 text-opacity-50 text-sm absolute bottom-0 left-0 flex items-center p-[12px] pointer-events-none text-theme_1_grey2 smScreen:p-[9px] rounded-bl-[4px] rounded-tl-[4px] border border-transparent focus:bg-theme_1_white focus:border-theme_1_black focus:border-r-0 w-full smScreen:text-base">
        {suggestionText}
      </span>}
      {searchInput && <button
        id='search-widget-clear'
        className={'absolute right-0 top-1/2 transform -translate-y-[40%] -translate-x-1/2'}
        onClick={clearInput}
        onMouseDown={handleMouseDown}
      >
        <Image
          src={crossIcon}
          width={20}
          height={20}
          alt='cross-icon'
        />
      </button>}
    </div>
    {!checkCountryPreference(userCountryPreference, COUNTRY_CODE.us) && !isSmScreen && <div className='flex items-center bg-theme_1_greybg peer-has-[:focus]:bg-theme_1_white peer-has-[:focus]:border peer-has-[:focus]:border-theme_1_black peer-has-[:focus]:border-l-0 peer-has-[:focus]:border-r-0'>
      <div className='flex items-center pl-2 pr-5 border-l border-theme_1_grey4'>
        <button
          id='search-widget-clear'
          className='flex items-center'
          onClick={() => {
            setImageSearchPopupVisible(true);
          }}
        >
          <Image
            src={imageSearchIcon}
            width={24}
            height={24}
            alt='image-search-icon'
          />
        </button>
      </div>
    </div>}
    <button
      id='search-widget-submit'
      className={`flex items-center bg-theme_1_black text-theme_1_white px-[20px] font-semibold rounded-[4px] py-[10px] smScreen:px-2 smScreen:py-[7px] border border-transparent ml-[-4px]`}
      value={searchInput}
      onClick={onSearch}
      disabled={!searchInput}
    >
      <Image
        src={searchIcon}
        width={24}
        height={24}
        alt='search-icon'
        loading="eager"
      />
      <span className={'ml-[4px] smScreen:hidden'}>Search</span>
    </button>


{(autoSuggestions.length && showAutoSuggestions) ? (
  <div
    id='search-widget-autosuggestion-list'
    className={'absolute w-full bottom-0 left-0 transform translate-y-[100%] bg-theme_1_white shadow-[0_4px_10px_rgba(0,0,0,0.1)] rounded-[4px]'}
  >
    {autoSuggestions.map((suggestion: CompletionResult, index: number) => (
      <button
        id={`search-widget-autosuggestion-item-${index}`}
        key={suggestion.text}
        className={'flex items-center p-[20px] w-full hover:bg-theme_1_greybg'}
        value={suggestion.text}
        onMouseDown={onClickSuggestion}
        onMouseEnter={() => handleMouseEnter(suggestion.text)}
        onMouseLeave={handleMouseLeave}
      >
        {suggestion.search_result_data.image_urls && (
          <div className={'relative w-10 h-10 mr-4'}>
            <Image
              src={suggestion.search_result_data.image_urls[0]}
              alt='Product'
              layout='fill'
              objectFit='cover'
              className={'rounded'}
            />
          </div>
        )}
        <div className={'text-left flex-1'}>
          {(suggestion.text === searchInput.toLowerCase())
            ? <div><b>{suggestion.text}</b></div>
            : <div dangerouslySetInnerHTML={{__html: suggestion.text?.replace(new RegExp(`(${searchInput})`, 'i'), '<span class="font-bold">$1</span>') || ''}} />}
          {/*suggestion.search_result_data.master_product_category_name && (
            <div className={'text-theme_1_grey2 text-[10px]'}>
              In <span className={'font-bold'}>{suggestion.search_result_data.master_product_category_name}</span> Category
            </div>
          )*/}
        </div>
        <Image
          src={arrowUpRightIcon}
          width={20}
          height={20}
          alt='arrow-up-icon'
        />
      </button>
    ))}
  </div>
) : null}

      {!isSmScreen && <ImageSearchPopup
        isVisible={imageSearchPopupVisible}
        toggle={() => {
          setImageSearchPopupVisible(flag => !flag);
        }}
      />}
    </div>
    {!checkCountryPreference(userCountryPreference, COUNTRY_CODE.us) && (isSmScreen && !searchInput) && <div className="p-4 bg-neutral-100 rounded-lg mt-5">
      <div className="flex items-center space-x-2 text-neutral-950 text-xs font-semibold leading-none mb-3">
        <Image
          src={imageSearchIcon}
          width={24}
          height={24}
          alt='image-search-icon'
        />
        <span>Search by Image</span>
      </div>
      <div className="flex justify-center items-center space-x-2.5">
        <div className="relative flex items-center space-x-2 bg-white w-full rounded border border-neutral-950 p-3 text-xs font-semibold">
          <Image
            src={cameraBlackIcon}
            width={24}
            height={24}
            alt='camera-icon'
          />
          <span>Click a photo</span>
          <input
            onChange={(e) => onImageUpload(e.target.files, 'click_photo')}
            className="absolute left-0 top-0 w-full h-full opacity-0 !m-0"
            type="file"
            capture
            accept="image/png,image/jpeg,image/jpg"
          />
        </div>
        <div className="relative flex items-center space-x-2 bg-white w-full rounded border border-neutral-950 p-3 text-xs font-semibold">
          <Image
            src={uploadCloudBlackIcon}
            width={24}
            height={24}
            alt='upload-icon'
          />
          <span>Upload a photo</span>
          <input
            onChange={(e) => onImageUpload(e.target.files, 'upload_photo')}
            className="absolute left-0 top-0 w-full h-full opacity-0 !m-0"
            type="file"
            accept="image/png,image/jpeg,image/jpg"
          />
        </div>
      </div>
      <Loader isVisible={isImageUploadLoading} />
    </div>}
  </>);
}

// Default Props
SearchWidget.defaultProps = {
  autoFocus: false,
}

export default SearchWidget;